import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App'; // Import your root component here

const container = document.getElementById('root');
const root = createRoot(container); // Create a root.


root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Check that service workers are supported
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then(reg => {
      console.log('Service Worker registered successfully.', reg);
    }).catch(err => {
      console.log('Service Worker registration failed:', err);
    });
  });
}
